/**
 * VIP页面接口
 */
import request from '@/utils/request.js'
import { getCookie } from './method'

export const getvipType = () => {
  return request({
    method: 'GET',
    url: '/api/vip/list',
    dataType:'json',
    headers: {
        'Authorization': getCookie('accessToken')
    },
  })
}
export const createOrder = data => {
  return request({
    method: 'POST',
    url: '/api/order/add',
    data,
    headers: {
      'Authorization': getCookie('accessToken'),
      "Content-Type":true
    },
  })
}
//查询订单是否支付
export const getIsPay = data => {
  return request({
    method: 'GET',
    url: `/api/oder/get?orderId=${data.orderId}&payType=${data.payType}`,
    headers: {
      'Authorization': getCookie('accessToken'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}