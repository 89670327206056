<template>
  <div>
    <div class="vipbody">
      <div class="paybody">
        <!-- 账号，未开通模块 -->
        <div class="paytop">
          <img :src="tx" alt="">
          <div class="top-item">
            <div class="item-topvip">
              <div class="user">账号:{{nickname}}</div>
              <div class="vipName">DOIT学院VIP</div>
              <div class="tip">{{vipType == 1?'您还未开通':'您已开通'}}</div>
            </div>
            <div class="item-bottomvip">
              <div class="noOpen" v-for="(item, index) in noOpens" :key="index">{{item.text}}</div>
            </div>
            <div class="payClose" @click="backInfo()"></div>
          </div>
        </div>
        <!-- 套餐 -->
        <div class="paycenter">
          <h3>
						<img src="../../assets/vip.png">
						<span>DOIT学院会员</span>
					</h3>
          <div class="setMeal">
            <div  v-for="(item, index) in JSON.parse(JSON.stringify(dataType))" :key="item.id" @click.native="select(index,item)" :class="['setMeal-item', {'border': border == index}]">
              <div>{{item.content}}</div>
              <div>￥<span>{{item.price}}</span></div>
              <!-- <div class="originalPrice">原价:{{item.originalPrice}}</div> -->
              <div class="recommend" v-if="index == 1">推荐</div>
            </div>
          </div>
        </div>
        <!-- 支付 -->
        <div class="paybottom">
          <div class="payMethod">
            <div :class="['payMethod-item',{'payMethod-click': payClick == index}]" v-for="(item, index) in payMethod" :key="item.id" @click="selectPay(index,item)">
            <img :src="item.img" alt=""></div>
          </div>
          <div class="payBottom-right">
            <div>
              <div class="payNumber">{{payMoney}}<span>元</span></div>
              <div class="vipMonth">{{vipMonth}}<span>会员</span></div>
            </div>
            <div class="payBtn" @click="pay()">￥{{payMoney}}确认支付</div>
          </div>
        </div>
        <!-- 支付宝支付表单 -->
        <!-- <yanzheng></yanzheng> -->
        <div v-html="zfbpay"></div>
        <!-- 微信支付二维码 -->
        
      </div>
    </div>
    <div v-if="wxshow"  class="wxshow">
      <div class="show-box">
        <div class="wxshow-text">请打开微信扫码支付</div>
        <qrcode-vue
          :value="wxCodeUrl"
          :size="300"
          level="H"
        />
      </div>
    </div>
    
 </div>
</template>

<script>
import './vip.css'
import { getvipType, createOrder, getIsPay } from '../../api/vip'
import { h } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { getCookie } from '../../api/method'
export default {
  name: '',
  data () {
    return {
      tx: require('../../assets/tx.jpg'),
      border: -1,
      nickname: window.localStorage.getItem('nickname'),
      vipType: getCookie('vipType'),
      noOpens: [ //未开通
        {
          id: 1,
          text: 'VIP视频免费看'
        },
        {
          id: 2,
          text: '全站PPT免费下载'
        },
        {
          id: 3,
          text: '特约课程9.5折'
        },
        {
          id: 4,
          text: '线下会议门票5折'
        }
      ],
      setMeal: [ //套餐
        {
          id: 1,
          month: '1个月',
          money: '12.00',
          originalPrice: '12.00', //原价
        },
        {
          id: 2,
          month: '3个月',
          money: '30.00',
          originalPrice: '30.00', //原价
        },
        {
          id: 1,
          month: '12个月',
          money: '88.00',
          originalPrice: '88.00', //原价
        },
      ],
      payMoney: '0.00',//支付金额
      vipMonth: '0个月',//会员时长
      payMethod: [
        {
          id: 1,
          img: require("../../assets/zfb.png"),
          payId: 1
        },
        {
          id: 2,
          img: require("../../assets/wx.png"),
          payId:2
        }
      ],
      payClick: -1,//支付方式的控制变量
      payType: '',//支付方式  //1支付宝 2微信
      dataType: [],
      vipTypeId: '',
      zfbpay: '',
      zfbshow: false,
      wxCodeUrl: '',
      wxshow: false

    }
  },
  props: {},
  components: {
    yanzheng: {
      render() {
        return h('div',{class: 'my-class'},this.zfbpay)
      }
    },
    QrcodeVue
  },
  mounted () {
    this.getType()
    this.txchange()
  },
  methods: {
    select(index,item) {
      this.border = index
      this.payMoney = item.price
      this.vipMonth = item.content
      this.vipTypeId = item.id
      
    },
    selectPay(index,item) {
      this.payClick = index
      this.payType = item.payId
      console.log(this.payClick,'payClick')
      
    },
    backInfo() {
      this.$router.push('/passportInfo')
    },
    //获取会员类型
    getType() {
      getvipType().then( res => {
        this.dataType = JSON.parse(JSON.stringify(res.data.data))
        // console.log(JSON.parse(JSON.stringify(this.dataType)),'1111')
        
        
      })
    },
    pay() { 
      
      if(this.vipTypeId == '') {
        this.$message({
          message: '请选择会员类型',
          type: 'warning'
        });
        return false
      }

      if(this.payType == '') {
        this.$message({
          message: '请选择支付方式',
          type: 'warning'
        });
        return false
      }
      const pay = {
        num: 1,
        payType: this.payType,
        vipTypeId: this.vipTypeId
      }
      console.log(pay)
      //创建订单
      createOrder(pay).then(res => {
        console.log(res)
        if(pay.payType == 2) {//微信
          console.log(res.data.data.wx.code_url)
          this.wxCodeUrl = res.data.data.wx.code_url
          this.wxshow = true
          const ispay = {
            orderId: res.data.data.orderId,
            payType: 2

          }
          console.log(ispay)
          //查询支付状态
          const timer = setInterval(() => {
            getIsPay(ispay).then(res => {
              console.log(res.data.data)
              if(res.data.data == true) {
                this.$router.push('/paySuccess')
                clearInterval(timer)
              }
            })
          },2000)
          
        }
        //支付宝
        if(pay.payType == 1) {
          console.log(res.data.data.zfb)
          this.zfbpay = res.data.data.zfb
          this.zfbshow = true
          setTimeout(() => {
            // console.log(document.forms[0])
            document.forms[0].submit()
          },100)
          // document.forms.submit();
          
        }
        
      })
    },
    txchange() {
      this.tx =  'https:' + getCookie('tx')
    }
  }
}
</script>

<style scoped lang='less'>
</style>
